export default {
  about_lingYing:"关于瓴鹰",
  product:"产品中心",
  solution:"解决方案",
  news:"新闻中心",
  servic_eand_support:"服务与支持",
  careers:"人才招聘",
  high_definition_full_color_night_vision_system:"瓴鹰高清全彩夜视系统",
  industrial_edge_intelligent_camera:"工业边缘智能相机",
  details:"查看详情",
  utility_model:"实用新型",
  appearance_patent:"外观专利",
  soft_patent: '软著专利',
  hisilicon:"海思",
  tongji_university:"同济大学",
  vanguard_group:"先导集团",
  institute_of_advanced_technology_university_of_science_and_technology_of_china:"中国科学技术大学先进技术研究院",
  provide_one_on_one_pre_sales_consulting_services:"提供一对一售前咨询服务",
  tailored_solutions:"量身定制解决方案",
  online_consultation:"在线咨询",
  contact_us:"联系我们",
  cell_phone:"联系电话",
  company_address_unit_CD608_Block_F4_8_china:"公司地址:广东省深圳市福田区泰然五路F4-8栋（天济大厦）CD座608",
  company_name_Shenzhen_lingYing_intelligent_technology_co_ltd:"公司名称：瓴鹰智能科技有限公司",
  scan_the_QR_code_to_follow_our_weChat_official_account:"扫一扫关注微信公众号",
  scan_the_QR_code_to_add_us_on_weChat:"扫一扫添加微信",
  ling_Ying:"瓴鹰智能",
  illuminating_every_darkness_in_the_world:"照亮世界每一处黑暗",
  lingying_intelligent_technology_is_technologies_vision_R_D_centers_in_Shanghai_Suzhou_and_hefei:"瓴鹰智能是一家前沿深度学习、计算机视觉、边缘计算智能相机系统为核心技术的高科技公司。总部位于深圳,在上海，苏州，合肥设有子公司和研发中心。",
  the_company_is_led_by_top_team_also_of_science_and_techuter_vision_conferences_including_CVPR_ICCV_and_ECCV:"公司由视觉AI的顶级专家领衔，有数名博士组成研发团队，同时与同济大学、香港科大、南洋理工等著名高校科研机构开展深度。在国际计算机视觉领域三大顶级会议（CVPR，ICCV，ECCV）发表多篇学术成果。",
  The_company_reviewers_for_more_than_5years:"IEEE会员，担任期刊及会议审稿人超过5年。跟踪类前沿成果全球第 一。具有上亿张超大规模自制高质量实景训练与测试数据集核心优势公司拥有完全自主知识产权多款边缘智能超高清全彩相机和工业边缘智能2D,3D相机等光学影像系列产品。",
  OTLE_Net_is_an_end_to_end_video_image:"(OTLE-Net)为复杂低照度动态环境下实现强鲁棒、高精度、快速目标跟踪的端到端视频图像增强深度学习神经网络，其中核心模块LEM（Low-light Enhancer Module）可将极弱光线环境下视频自适应学习推理至白天彩色效果，并全面提升画质效果，符合人眼视觉最佳特性 实现各类极端光照条件下高清真彩成像。",
  We_are_committed:"我们一直在创新路上，为客户供视觉相关个性化的系列智能硬件和行业解决方案。为客户提升业务运营效率，实现创新应用场景。",
  leading_the_industry_in_high_definition:"高清全彩视觉与边缘智能计算行业引领者",
  zz:"致力于成为一家世界领先的人工智能与计算机视觉综合解决方案",
  illuminating_every_corner_of_the_world:"提供商照亮世界每一处黑暗！",
  lndustry_leader:"行业引领者",
  technological_leadership:"技术领先",
  efficient_service:"高效服务",
  information_confidentiality:"信息保密",
  professional_quality:"专业品质",
  full_service:"全程服务",
  ling_ying_ultra_high_definition:"瓴鹰超高清夜视系统",
  industrial_edge_intelligent_camera:"工业边缘智能相机",
  lingying_industrial_edge_intelligent_camera:"瓴鹰工业边缘智能相机",
  an_all_weather_high_definition:"全天候高清全彩边缘智能视觉系统,<br>可搭载在车辆、船舶、飞行器、潜航器、塔楼、<br>洞穴、森林、天文台、电力设施等，<br>可极大拓展夜间极暗场景的实时高清全彩监控<br>与潜在未知信息挖掘",
  extremely_low_light_imaging:"极弱光成像",
  intelligent_low_Light_enhancement:"智能微光增强色彩恢复",
  attention_mechanism:"注意力机制动态去噪",
  adaptive_image:"自适应图像超分辨",
  target_tracking:"目标跟踪",
  strong_defogging_capabilities:"超强去雾",
  product_advantages:"产品优势",
  through_edge_deployment_restoration_complex:"通过边缘部署智能微光增强色彩恢复、注意力机制动态去噪与自适应图像超分辨等先进功能,可实现极端复杂动态环境下实时高精度、强鲁棒的常见目标（含人、车、火焰等）检测与任意目标跟踪等。无需补光，突破实现极弱光照环境下视频自适应学习恢复至白天全彩效果，并全面提升画质效果",
  specifications_and_parameters:"规格参数",
  effect_display:"效果展示",
  lingying_industrial_edge_user_programmable_developing:"具有多处理器功能和用户可编程能力。<br>提供无与伦比的相机硬件访问用来以<br>开发和运行自定义图像处理",
  measurement:"测量",
  detection:"检测",
  localization:"定位",
  identification:"识别",
  hat_mask_and_glove_detection:"帽子、口罩、手套检测",
  human_body_posture_detection:"人体姿态检测",
  with_high_performance_innovative_ai_agriculture:"以高性能、小尺寸和易开发的特性，打开AI视觉解决方案的创新之门，适用于制造、物流、零售、服务、农业、智慧城市、医疗、生命科学以及其他领域的边缘应用。",
  effect_display:"效果展示",
  length_measurement:"长度检测",
  arc_and_radius_measurement:"圆弧及半径测量",
  angle_measurement:"角度测量",
  defective_product:"次品检测",
  digital_statistics:"数字统计",
  customs_anti_smuggling:"海关缉私",
  campus_safety:"校园安全",
  public_safety:"公共安全",
  forest_fire_prevention:"森林防火",
  oil_drilling:"石油钻井",
  emergency_management:"应急管理",
  nighttime_reconnaissance:"夜间侦察",
  river_inspection:"河道检测",
  unmanned_aerial_vehicle:"无人机",
  news:"新闻中心",
  keep_up_news_Lingying:"记录最新资讯，了解瓴鹰向前迈进的每一个瞬间",
  OTLE_NET_and_Edge_Intelligence_system:"OTLE-NET及边缘智能系统",
  OTLE_NET_short_environment_net_is_an_end_to_end_video_enhancement_edge:"OTLE-NET全名Object Tracking in Low-light Environment-Net，为复杂低照度动态环境下实现强鲁棒、高精度、快速目标跟踪的端到端视频图像增强深度学习神经网络；<br><br>OTLE-Net及边缘智能系统，具有AI图像超分辨率、微光增强色彩恢复、自适应动态去噪、自适应目标检测与跟踪和并行边缘计算功能。",
  comprehensive_pre_services_reliable_assurance:"完善的售前售后服务，为您提供稳定的保障",
  warranty_terms:"保固条款",
  disclaimer:"免责权限",
  in_case_of_any_disputes_arising:"使用本网站出现纠纷问题，最终解释权归本公司所有",
  repair_application:"维修申请",
  contact_for_repair:"维修联系",
  download_area:"下载专区",
  download: "下载",
  ling_ying_OF:"瓴鹰of",
  careers:"人才招聘",
  join_us_to_accelerate_the_landing:"和我们一起加速人工智能落地",
  intelligent_job_1have_2develop_4independently:"智能硬件销售经理<br>职位描述发布时间：2022-07-12 16:35:21<br>岗位职责：<br>1、有安防智能相机和工业智能相机经验以及有一定销售渠道；<br>2、根据市场和公司的战略规划，制定个人的销售计划和目标；<br>3、可独立制订并实施有效的开拓谈判计划；<br>4、开发新终端客户、新市场，增加产品销售范围，并维护重要客户；<br>5、完成上级分配销售业务；<br>6、热爱销售、性格活泼，乐观外向，责任心强。<br>任职要求：<br>1.了解人工智能，对监控摄像头领域的资源积累；<br>2.做过人工智能技术解决方案和安防监控；<br>3.有至少2年的大客户销售经验，有一定的大客户资源积累；<br>4.目标感强，良好的交际能力，抗压能力强等；<br>5.具备高效的沟通能力和人际关系处理能力；<br>6.会做文案、PPT ,运营公众号；<br>7.形象好，气质佳，英文好，热爱销售、性格活泼，乐观外向，责任心强；<br>8：能经常出差，参加国内外展会；<br>9：年龄在25-35岁周岁，男女不限。",
  iOS_job_job_1_engage2_complete_development_3_Assist_4_analyze:"ios开发<br>职位描述发布时间：2022-07-12 16:35:57<br>岗位职责：<br>1、 从事ISO 平台应用软件产品研发工作；<br>2、 完成软件系统代码的实现，编写代码注释和开发文档；<br>3、 辅助进行系统的功能定义和程序设计；<br>4、 分析并解决软件开发过程中的问题；<br>5、 协助测试工程师制定测试计划，定位发现的问题；<br>6、 配合项目经理完成相关任务目标<br><br>任职要求：<br>1、 大学本科及以上学历,2年以上技术研发工作经历<br>2、 熟悉Eclipse等编程工具及ISO平台技术构架<br>3、 熟悉面向对象编程知识，熟练使用Java编程技术<br>4、 熟悉关系数据库相关知识，熟悉SQL语法<br>5、 热爱编程，富有团队协作精神",
  android_1_responsible_OpenCL_preferred_massive:"安卓工程师<br>职位描述发布时间：2022-07-12 16:35:21<br>岗位职责：<br>1、负责机器视觉项目的前期技术探索、分析及实现<br>2、其他项目所需算法技术支持<br>3、将相关算法及平台落地为实际效益效益项目<br>4、图像质量评估及相关验证工具开发及相关技术文档编写<br><br>任职要求：<br>1、本科及以上学历，计算机、数学等相关专业，具备扎实的数理功底及概率统计基础<br>2、工作经验3年以上<br>3、熟练数字图像处理的各种算法原理及数学模型建立，熟练使用OpenCV图像处理库，熟练图像配准技术<br>4、精通海量数据计算的速度及性能优化<br>5、精通C#底层工作原理及运行原理优先<br>6、熟练OpenCL或CUDA优先<br>7、熟练形位工差算法优先<br>8、熟练神经网络优先<br>",
  parameter_img: 'images/product/product1/parameter.png',
  job_description: '职位描述',
  release_time: '发布时间',
  job_responsibilities: '岗位职责',
  job_requirements: '任职要求',
  service_and_support: '服务与支持',
  provide_a_full: '提供全栈式AI视觉方案'
}