const VueAxios = {
    vm: {},
    // eslint-disable-next-line no-unused-vars
    install(Vue, instance) {

        if (!instance) {
            // eslint-disable-next-line no-console
            console.error('You have to install axios')
            return
        }

        Vue.axios = instance

        Object.defineProperties(Vue.prototype, {
            axios: {
                get: function get() {
                    return instance
                }
            },
            $http: {
                get: function get() {
                    return instance
                }
            }
        })
    }
}

export {
    VueAxios
}