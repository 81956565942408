<template>
  <div class="wrap">
    <div
      class="item"
      v-for="(item, index) in enterpriseList"
      :key="index"
      @click="handleClickEnterprise(item.url)"
    >
      <img :src="item.url" alt="" />
      <p>{{ $t(item.title) }}</p>
    </div>

    <el-dialog :visible.sync="dialogVisible">
      <img :src="showImageUrl" alt="" width="100%">
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showImageUrl: '',
      dialogVisible: false,
      enterpriseList: [
        { url: '/images/enterprise/基于嵌入式深度神经网络的人物追踪自拍杆  专利证书.jpg', title: 'utility_model' },
        { url: '/images/enterprise/瓴鹰智能-一种深度相机-实用新型专利证书-ZL202021677097.7_00.jpg', title: 'utility_model' },
        { url: '/images/enterprise/瓴鹰智能--一种具有罩体防护结构的超高清夜视摄像机-实用新型专利证书(签章)_00.jpg', title: 'utility_model' },
        { url: '/images/enterprise/3D感测相机（边缘计算）专利证书_00.jpg', title: 'appearance_patent' },
        { url: '/images/enterprise/外观设计专利证书（签章）-瓴鹰智能-2022300316705-工业智能相机(无纸档)_00.jpg', title: 'appearance_patent' },
        { url: '/images/enterprise/低照度.jpg', title: 'soft_patent' },
        { url: '/images/enterprise/云台.jpg', title: 'soft_patent' },
        { url: '/images/enterprise/工业边缘智能视觉系统V1.0-软著证书证书.jpg', title: 'soft_patent' },
        { url: '/images/enterprise/工业边缘智能相机电路板.jpg', title: 'appearance_patent' },
        { url: '/images/enterprise/手机AI跟拍器 专利证书_00.jpg', title: 'appearance_patent' },
      ],
    }
  },
  methods: {
    handleClickEnterprise(url) {
      this.showImageUrl = url
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  display: flex;
  flex-wrap: wrap;

  .item {
    width: 20%;
    padding: 20px;
    cursor: pointer;

    p {
      font-size: 14px;
      text-align: center;
      font-weight: bold;
    }

    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 750px) {
  .item {
    width: 50% !important;
  }
}
</style>