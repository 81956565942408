export default {
  about_lingYing:"About LingYing",
  product:"Product",
  solution:"Solution",
  news:"News",
  servic_eand_support:"Service and Support",
  careers:"Careers",
  high_definition_full_color_night_vision_system:"High-Definition Full-Color Night Vision System",
  industrial_edge_intelligent_camera:"Industrial Edge Intelligent Camera",
  details:"Details",
  utility_model:"Utility Model",
  appearance_patent:"Appearance Patent",
  soft_patent: 'Soft patent',
  hisilicon:"Hisilicon",
  tongji_university:"Tongji University",
  vanguard_group:"Vanguard Group",
  institute_of_advanced_technology_university_of_science_and_technology_of_china:"Institute of Advanced Technology, University of Science and Technology of China",
  provide_one_on_one_pre_sales_consulting_services:"Provide one-on-one pre-sales consulting services",
  tailored_solutions:"Tailored solutions",
  online_consultation:"Online consultation",
  contact_us:"Contact Us",
  cell_phone:"Cell Phone",
  company_address_unit_CD608_Block_F4_8_china:"Company Address: Unit CD608, Block F4-8, Tianji Building, Taieran 5th Road, Futian District, Shenzhen, Guangdong Province, China",
  company_name_Shenzhen_lingYing_intelligent_technology_co_ltd:"Company Name: Shenzhen LingYing Intelligent Technology Co., Ltd.",
  scan_the_QR_code_to_follow_our_weChat_official_account:"Scan the QR code to follow our WeChat official account",
  scan_the_QR_code_to_add_us_on_weChat:"Scan the QR code to add us on WeChat",
  ling_Ying:"LingYing",
  illuminating_every_darkness_in_the_world:"illuminating every darkness in the world!",
  lingying_intelligent_technology_is_technologies_vision_R_D_centers_in_Shanghai_Suzhou_and_hefei:"Lingying Intelligent Technology is a high-tech company with cutting-edge technologies in deep learning, computer vision, and edge computing intelligent camera systems. It is headquartered in Shenzhen, with subsidiaries and R&D centers in Shanghai, Suzhou, and Hefei.",
  the_company_is_led_by_top_team_also_of_science_and_techuter_vision_conferences_including_CVPR_ICCV_and_ECCV:"The company is led by top experts in visual AI and has a research and development team consisting of several PhDs. It has also established collaborations with renowned research institutions at famous universities such as Tongji University, the Hong Kong University of Science and Technology, and Nanyang Technological University to conduct in-depth research. The company has published multiple academic achievements at the top three international computer vision conferences, including CVPR, ICCV, and ECCV.",
  The_company_reviewers_for_more_than_5years:"The company's team members are IEEE members and have served as journal and conference reviewers for more than 5 years. They are globally recognized for tracking cutting-edge achievements in related fields. The company possesses a core advantage of producing self-made, high-quality, and large-scale real-scene training and testing datasets, which have exceeded 100 million images. It has complete independent intellectual property rights for a series of optical imaging products, including edge intelligent high-definition full-color cameras, industrial edge intelligent 2D and 3D cameras, and more.",
  OTLE_Net_is_an_end_to_end_video_image:"OTLE-Net is an end-to-end video image enhancement deep learning neural network that enables robust detection, high-precision, and fast object tracking in complex low-light dynamic environments. Core module,LEM (Low-light Enhancer Module), which can adaptively learn and infer videos in extremely low-light environments to achieve a daytime color effect and comprehensively improve image quality, in accordance with the best characteristics of human visual perception. OTLE-Net can achieve high-definition and true-color imaging under various extreme lighting conditions.",
  We_are_committed:"We are committed to innovation and providing customers with personalized series of intelligent hardware and industry solutions related to vision. Our aim is to help customers Improve business operation efficiency and realize innovative application scenarios.",
  leading_the_industry_in_high_definition:"Leading the industry in high-definition full-color vision and edge intelligent computing",
  zz:"committed to becoming a world-leading comprehensive solution provider for artificial",
  illuminating_every_corner_of_the_world:"intelligence and computer vision illuminating every corner of the world!",
  lndustry_leader:"Lndustry Leader",
  technological_leadership:"Technological Leadership",
  efficient_service:"Efficient Service",
  information_confidentiality:"Information Confidentiality",
  professional_quality:"Professional Quality",
  full_service:"Full-service",
  ling_ying_ultra_high_definition:"LingYing Ultra-High-Definition Night Vision System",
  lingying_industrial_edge_intelligent_camera:"LingYing Industrial Edge Intelligent Camera",
  industrial_edge_intelligent_camera:"Industrial Edge Intelligent Camera",
  an_all_weather_high_definition:"An all-weather high-definition full-color edge intelligent vision system, can be installed on vehicles, ships, aircraft, submarines, towers, caves, forests, observatories, power facilities, etc., greatly expanding real-time high-definition full-color monitoring of extremely dark scenes at night and potential unknown information mining",
  extremely_low_light_imaging:"Extremely Low-Light Imaging",
  intelligent_low_Light_enhancement:"Intelligent Low-Light Enhancement And Color Restoration",
  attention_mechanism:"Attention Mechanism Dynamic Noise Reduction",
  adaptive_image:"Adaptive Image Super-Resolution",
  target_tracking:"Target Tracking",
  strong_defogging_capabilities:"Strong Defogging Capabilities",
  product_advantages:"Product Advantages",
  through_edge_deployment_restoration_complex:"Through edge deployment of advanced features such as intelligent low-light enhancement and color restoration, attention mechanism dynamic noise reduction, and adaptive image super-resolution, it is possible to achieve real-time high-precision and strong-robust detection of common targets (including people, vehicles, fires, etc.) and any object tracking in extremely complex and dynamic environments. No additional lighting is required, and it can adaptively learn and recover videos to full-color daytime effects in extremely low-light environments, comprehensively improving the visual quality",
  specifications_and_parameters:"Specifications And Parameters",
  effect_display:"Effect Display",
  lingying_industrial_edge_user_programmable_developing:"Lingying Industrial Edge Intelligent Camera has multi-processor functions and user-programmable capabilities. It provides unparalleled camera hardware access for developing and running custom image processing",
  measurement:"Measurement",
  detection:"Detection",
  localization:"Localization",
  identification:"Identification",
  hat_mask_and_glove_detection:"Hat, Mask, and Glove Detection",
  human_body_posture_detection:"Human Body Posture Detection",
  with_high_performance_innovative_ai_agriculture:"With high performance, small size, and easy development characteristics, it opens the door to innovative AI visual solutions and is suitable for edge applications in manufacturing, logistics, retail, service, agriculture, smart cities, healthcare, life sciences, and other fields",
  effect_display:"Effect Display",
  length_measurement:"Length Measurement",
  arc_and_radius_measurement:"Arc and Radius Measurement",
  angle_measurement:"Angle Measurement",
  defective_product:"Defective Product Detection",
  digital_statistics:"Digital Statistics",
  customs_anti_smuggling:"Customs Anti-smuggling",
  campus_safety:"Campus Safety",
  public_safety:"Public Safety",
  forest_fire_prevention:"Forest Fire Prevention",
  oil_drilling:"Oil Drilling",
  emergency_management:"Emergency Management",
  nighttime_reconnaissance:"Nighttime Reconnaissance",
  river_inspection:"River Inspection",
  unmanned_aerial_vehicle:"Unmanned Aerial Vehicle",
  news:"News",
  keep_up_news_Lingying:"Keep up with the latest news and every moment of Lingying's progress",
  OTLE_NET_and_Edge_Intelligence_system:"OTLE-NET and Edge Intelligence System",
  OTLE_NET_short_environment_net_is_an_end_to_end_video_enhancement_edge:"OTLE-NET, short for Object Tracking in Low-light Environment-Net, is an end-to-end video image enhancement deep learning neural network that achieves strong robustness, high accuracy, and fast target tracking in complex low-light dynamic environments.<br>OTLE-Net and Edge Intelligence System have AI image super-resolution, low-light color restoration, adaptive dynamic noise reduction, adaptive target detection and tracking, and parallel edge computing functions.",
  comprehensive_pre_services_reliable_assurance:"Comprehensive pre-sales and after-sales services provide you with reliable assurance",
  warranty_terms:"Warranty Terms",
  disclaimer:"Disclaimer",
  in_case_of_any_disputes_arising:"In case of any disputes arising from the use of this website, the final interpretation rights belong to our company.",
  repair_application:"Repair Application",
  contact_for_repair:"Contact for Repair",
  download_area:"Download Area",
  download: "Download",
  ling_ying_OF:"LingYing OF",
  careers:"Careers",
  join_us_to_accelerate_the_landing:"Join us to accelerate the landing of artificial intelligence.",
  intelligent_job_1have_2develop_4independently:"Intelligent Hardware Sales Manager<br>职位描述发布时间：2022-07-12 16:35:21<br>岗位职责：<br>1、有安防智能相机和工业智能相机经验以及有一定销售渠道；<br>2、根据市场和公司的战略规划，制定个人的销售计划和目标；<br>3、可独立制订并实施有效的开拓谈判计划；<br>4、开发新终端客户、新市场，增加产品销售范围，并维护重要客户；<br>5、完成上级分配销售业务；<br>6、热爱销售、性格活泼，乐观外向，责任心强。<br>任职要求：<br>1.了解人工智能，对监控摄像头领域的资源积累；<br>2.做过人工智能技术解决方案和安防监控；<br>3.有至少2年的大客户销售经验，有一定的大客户资源积累；<br>4.目标感强，良好的交际能力，抗压能力强等；<br>5.具备高效的沟通能力和人际关系处理能力；<br>6.会做文案、PPT ,运营公众号；<br>7.形象好，气质佳，英文好，热爱销售、性格活泼，乐观外向，责任心强；<br>8：能经常出差，参加国内外展会；<br>9：年龄在25-35岁周岁，男女不限。",
  iOS_job_job_1_engage2_complete_development_3_Assist_4_analyze:"ios开发<br>职位描述发布时间：2022-07-12 16:35:57<br>岗位职责：<br>1、 从事ISO 平台应用软件产品研发工作；<br>2、 完成软件系统代码的实现，编写代码注释和开发文档；<br>3、 辅助进行系统的功能定义和程序设计；<br>4、 分析并解决软件开发过程中的问题；<br>5、 协助测试工程师制定测试计划，定位发现的问题；<br>6、 配合项目经理完成相关任务目标<br><br>任职要求：<br>1、 大学本科及以上学历,2年以上技术研发工作经历<br>2、 熟悉Eclipse等编程工具及ISO平台技术构架<br>3、 熟悉面向对象编程知识，熟练使用Java编程技术<br>4、 熟悉关系数据库相关知识，熟悉SQL语法<br>5、 热爱编程，富有团队协作精神",
  android_1_responsible_OpenCL_preferred_massive:"安卓工程师<br>职位描述发布时间：2022-07-12 16:35:21<br>岗位职责：<br>1、负责机器视觉项目的前期技术探索、分析及实现<br>2、其他项目所需算法技术支持<br>3、将相关算法及平台落地为实际效益效益项目<br>4、图像质量评估及相关验证工具开发及相关技术文档编写<br><br>任职要求：<br>1、本科及以上学历，计算机、数学等相关专业，具备扎实的数理功底及概率统计基础<br>2、工作经验3年以上<br>3、熟练数字图像处理的各种算法原理及数学模型建立，熟练使用OpenCV图像处理库，熟练图像配准技术<br>4、精通海量数据计算的速度及性能优化<br>5、精通C#底层工作原理及运行原理优先<br>6、熟练OpenCL或CUDA优先<br>7、熟练形位工差算法优先<br>8、熟练神经网络优先<br>",
  parameter_img: 'images/product/product1/parameter_en.jpeg',
  job_description: 'Job description',
  release_time: 'Release time',
  job_responsibilities: 'Job Responsibilities',
  job_requirements: 'Job Requirements',
  service_and_support: 'Service and Support',
  provide_a_full: 'Provide a full-stack AI vision solution'
}