<template>
  <div :class="isMobile ? 'nav-menu' : ''" v-show="show">
    <el-menu
      :default-active="activeIndex2"
      :mode="isMobile ? 'vertical' : 'horizontal'"
      router
      @select="handleSelect"
      background-color="#040D37"
      text-color="#fff"
      active-text-color="#fff"
    >
      <el-menu-item index="/company">{{ $t('about_lingYing') }}</el-menu-item>
      <el-submenu index="/product">
        <template slot="title">
          <span @click="$router.push('/product')">{{ $t('product') }}</span>
        </template>
        <el-menu-item index="/productDetail/1">{{ $t('high_definition_full_color_night_vision_system') }}</el-menu-item>
        <el-menu-item index="/productDetail/2">{{ $t('industrial_edge_intelligent_camera') }}</el-menu-item>
      </el-submenu>
      <el-menu-item index="/solution">{{ $t('solution') }}</el-menu-item>
      <el-menu-item index="/news">{{ $t('news') }}</el-menu-item>
      <el-menu-item index="/serve">{{ $t('servic_eand_support') }}</el-menu-item>
      <el-menu-item index="/recruit">{{ $t('careers') }}</el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "1",
      isMobile: false
    }
  },
  mounted() {
    this.resize()
    window.addEventListener('resize', this.resize)
  },
  destroyed() {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
    },
    resize() {
      if(window.innerWidth < 750) {
        this.isMobile = true
      }else {
        this.isMobile = false
      }
    }
  },
}
</script>

<style>
.el-menu,
.el-submenu__title,
.el-menu-item {
  border-bottom: none !important;
}

.el-menu-item:hover,
.el-submenu__title:hover {
  background-color: transparent !important;
}

.nav-menu {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 100px;
  z-index: 999;
}

.el-submenu .el-submenu__title {
  line-height: 57px !important;
}
</style>
