<template>
    <div class="warper">
        <h2 class="title text-[20px] md:text-[30px]" :style="dark && 'color:#fff;'">{{ title }}</h2>
        <h3 class="sub_title text-[14px] md:text-[35px]" :style="!dark && 'color:#dddddd;'">{{ subTitle }}</h3>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        subTitle: String,
        dark: Boolean
    }
}
</script>

<style scoped>
.warper {
    height: 200px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}
.title {
    font-weight: bold;
    position: relative;
    color: #373737;
}

.sub_title {
    font-weight: 350;
    color: #454a53;
    text-transform:uppercase;
    letter-spacing: 10px;
}
</style>