<template>
    <div class="consulting">
        <item-title title="业务咨询" sub-title="business consulting" dark />
        <div class="contentDiv flex">
            <div class="content">
                <div class="title">{{ $t('provide_one_on_one_pre_sales_consulting_services') }} <br> {{ $t('tailored_solutions') }}</div>
                <div class="btn">
                    <a href="tel:13602644760">{{ $t('online_consultation') }}</a>
                </div>
            </div>
            <div>
                <img src="@/assets/images/48d9e753310d7b7785b557b17489190.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import itemTitle from '@/components/item-title.vue'
export default {
    components: {
        itemTitle
    }
}
</script>

<style lang="less" scoped>
.consulting {
    background: url(@/assets/images/consulting-bg.png) no-repeat;
    background-size: cover;
    height: 600px;
    color: #fff;

    .content {
        .title {
            font-size: 40px;
            font-weight: 800;
            line-height: 50px;
            padding-top: 40px;
        }

        .btn {
            margin-top: 40px;
            background-color: #01214a;
            padding: 10px 20px;
            border-radius: 5px;
            display: inline-block;
            a{
                color: #fff;
                text-decoration: none;
            }
        }
    }

    img {
        width: 100%;
    }

}

.contentDiv {
    max-width: 1200px;
    margin: auto;
    padding: 0 50px;

    >div:first-child {
        flex: 3;
    }

    >div:last-child {
        flex: 2;
    }
}

@media screen and (max-width: 750px) {
    .contentDiv {
        display: block !important;
        padding: 0 30px;
    }

    img {
        display: none;
    }

    .content {
        flex: 1 !important;
        width: 100% !important;

        .title {
            font-size: 30px !important;
        }
    }
}</style>