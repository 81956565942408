import request from "../utils/request";
const api = {
        index: "index/index",
        about: "index/about",
        news: "index/news",
        product: "index/product",
        question: "index/question",
        cases: "index/cases",
        recruit: "index/recruit",
        productDetail: "index/productDetail/",
        newsDetail: "index/newsDetail/",
        casesDetail: "index/casesDetail/",
        questionDetail: 'index/questionDetail/',
        service: 'index/service',

    }
    // 首页
export function index() {
    return request({
        url: api.index,
        method: 'get',
    })
}
// 关于我们
export function about() {
    return request({
        url: api.about,
        method: 'get',
    })
}
// 新闻中心
export function news(params) {
    return request({
        url: api.news,
        method: 'get',
        params: params
    })
}
// 产品中心
export function product(params) {
    return request({
        url: api.product,
        method: 'get',
        params: params
    })
}
// 解决方案
export function question(params) {
    return request({
        url: api.question,
        method: 'get',
        params: params
    })
}
// 服务与支持
export function service() {
    return request({
        url: api.service,
        method: 'get',
    })
}
// 案例展示
export function cases(params) {
    return request({
        url: api.cases,
        method: 'get',
        params: params
    })
}
// 招聘
export function recruit() {
    return request({
        url: api.recruit,
        method: 'get',
    })
}
// 产品详情
export function productDetail(params) {
    return request({
        url: api.productDetail + params,
        method: 'get',
    })
}
// 新闻详情
export function newsDetail(params) {
    return request({
        url: api.newsDetail + params,
        method: 'get',
    })
}
// 案例详情
export function casesDetail(params) {
    return request({
        url: api.casesDetail + params,
        method: 'get',
    })
}
// 解决方案
export function questionDetail(params) {
    return request({
        url: api.questionDetail + params,
        method: 'get',
    })
}