import axios from 'axios'
import { storage } from '../utils/storage'
import qs from 'qs'
import { message } from "element-ui";
import { VueAxios } from './axios'
// 创建 axios 实例
const debug = true
const request = axios.create({
    // API 请求的默认前缀
    baseURL: 'http://lingyinghoutai.lingyingrobot.com/api/',
    timeout: 30000, // 请求超时时间
    headers: {
        'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }
})

// 异常拦截处理器
const errorHandler = (error) => {
    if (error.response) {
        const token = storage.get('token')
        const data = error.response.data
            // 从 localstorage 获取 token
        if (error.response.status === 403) {
            message.fail(data.msg)
        }
        if (error.response.status === 401 && !data.data) {
            message.fail(data.msg)
            if (token) {
                // store.dispatch('Logout').then(() => {
                //     setTimeout(() => {
                //         window.location.reload()
                //     }, 1500)
                // })
            }
        }
    }
    return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
    config.data = qs.stringify(config.data) // 转为formdata数据格式
    return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
    return response.data
}, errorHandler)

const installer = {
    vm: {},
    install(Vue) {
        Vue.use(VueAxios, request)
    }
}

export default request

export {
    installer as VueAxios,
    request as axios
}