import Vue from 'vue'
import App from './App.vue'
import router from './router'

import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui'
Vue.use(ElementUI);

import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, { ak: 'YE1dk5C3YHhzeMPRj1fEnb3AEjppZ1Ww' })

import 'animate.css'
import 'normalize.css'
import './styles/index.less'

import "tailwindcss/tailwind.css"

Vue.config.productionTip = false

import i18n from './lang'

new Vue({
    i18n,
    router,
    render: h => h(App)
}).$mount('#app')