import Vue from "vue"
import VueRouter from "vue-router"
import HomeView from "../views/HomeView.vue"

import Layout from "../layout/index.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "",
        name: "home",
        component: HomeView,
      },
      {
        path: "/company",
        name: "company",
        component: () => import("@/views/aboutCompany.vue"),
      },
      {
        path: "/news",
        name: "news",
        component: () => import("@/views/news.vue"),
      },
      {
        path: "/product",
        name: "product",
        component: () => import("@/views/product.vue"),
      },
      {
        path: "/solution",
        name: "solution",
        component: () => import("@/views/solution.vue"),
      },
      {
        path: "/case",
        name: "case",
        component: () => import("@/views/case.vue"),
      },
      {
        path: "/serve",
        name: "serve",
        component: () => import("@/views/serve.vue"),
      },
      {
        path: "/recruit",
        name: "recruit",
        component: () => import("@/views/recruit.vue"),
      },
      {
        path: "/article",
        name: "article",
        component: () => import("@/views/article.vue"),
      },
      {
        path: "/productDetail/:id",
        name: "productDetail",
        component: () => import("@/views/productDetail.vue"),
      },
      {
        path: "/technology",
        name: "technology",
        component: () => import("@/views/technology.vue"),
      },
    ],
  },
]

const router = new VueRouter({
  routes,
})

// 路由重复跳转报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

export default router
