<template>
  <div>
    <div class="tabbar">
      <ul class="text-white flex flex-wrap md:justify-center w-full text-center nav">
        <li class="w-1/2 md:w-auto py-4 cursor-pointer md:px-6" @click="hanleClick('/company')">{{ $t('about_lingYing') }}</li>
        <li class="w-1/2 md:w-auto py-4 cursor-pointer md:px-6" @click="hanleClick('/product')">{{ $t('product') }}</li>
        <li class="w-1/2 md:w-auto py-4 cursor-pointer md:px-6" @click="hanleClick('/solution')">{{ $t('solution') }}</li>
        <li class="w-1/2 md:w-auto py-4 cursor-pointer md:px-6" @click="hanleClick('/news')">{{ $t('news') }}</li>
        <li class="w-1/2 md:w-auto py-4 cursor-pointer md:px-6" @click="hanleClick('/serve')">{{ $t('servic_eand_support') }}</li>
        <li class="w-1/2 md:w-auto py-4 cursor-pointer md:px-6" @click="hanleClick('/recruit')">{{ $t('careers') }}</li>
      </ul>
      <div class="betweenFlex">
        <div>
          <div class="title">{{ $t('contact_us') }}</div>
          <div class="itemsCenter usText" v-if="indexData.foot">
            <img src="@/assets/images/phone.png" alt="" />
            <span><span>{{ $t('cell_phone') }}</span>：{{ indexData.foot.mobile }}</span>
          </div>
          <div class="itemsCenter usText" v-if="indexData.foot">
            <img src="@/assets/images/email.png" alt="" />
            <span>E-mail：{{ indexData.foot.email }}</span>
          </div>
          <div class="itemsCenter usText" v-if="indexData.foot">
            <img src="@/assets/images/name.png" alt="" />
            <span>{{ $t('company_name_Shenzhen_lingYing_intelligent_technology_co_ltd') }}</span>
          </div>
          <div class="itemsCenter usText" v-if="indexData.foot">
            <img src="@/assets/images/dizhi.png" alt="" />
            <span>{{ $t('company_address_unit_CD608_Block_F4_8_china') }}</span>
          </div>
        </div>
        <div class="mt-10 md:mt-0">
          <div class="ewmBox" v-if="indexData.foot">
            <img :src="indexData.foot.wechat" class="ewm" alt="" />
            <p>{{ $t('scan_the_QR_code_to_follow_our_weChat_official_account') }}</p>
          </div>
          <div class="ewmBox" v-if="indexData.foot">
            <img :src="indexData.foot.weixin" class="ewm" alt="" />
            <p>{{ $t('scan_the_QR_code_to_add_us_on_weChat') }}</p>
          </div>
        </div>
      </div>
      <div class="copyright" v-if="indexData.foot">
        {{ indexData.foot.copyright }}
        <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备19153949号-1</a>
      </div>
    </div>
  </div>
</template>

<script>
import { index } from "@/api/index";
export default {
  data() {
    return {
      indexData: {},
    };
  },
  mounted() {
    this._index();
  },
  methods: {
    // 首页数据
    _index() {
      index().then((res) => {
        this.indexData = res.data;
      });
    },
    hanleClick(path) {
      this.$router.push(path)
      window.scrollTo(0, 0);
    }
  },
};
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
  color: #409eff;
}

.tabbar {
  background: #01214a;

  >.betweenFlex {
    max-width: 1200px;
    width: 100%;
    margin: auto;
    padding: 40px;
    flex-wrap: wrap;

    >div:first-child {
      padding: 20px 40px;
      background-color: #011e42;

      span {
        line-height: 20px;
      }
    }

    >div:last-child{
      display: flex;
      > div{
        margin: 0 20px;
      }
    }

    .title {
      font-size: 20px;
      color: #fff;
      margin-bottom: 10px;
    }

    .navItem {
      font-size: 14px;
      color: #fff;
      margin-top: 20px;

      span+span {
        margin-left: 32px;
      }
    }

    .usText {
      margin-top: 20px;
      font-size: 14px;
      color: #fff;

      img {
        margin-right: 8px;
        width: 16px;
        height: 16px;
      }
    }
  }

  .ewmBox {
    font-size: 14px;
    color: #fff;
    text-align: center;

    .ewm {
      width: 130px;
      height: 130px;
      margin-bottom: 16px;
    }
  }

  .copyright {
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 16px;
    border-top: 1px solid rgba(255, 255, 255, 0.13);
    padding: 26px 0;
  }

  .copyright a {
    color: #fff;
  }
}

.nav {
  border-bottom: 1px solid #233e62;
}
</style>