<template>
  <div class="home">
    <div class="banner">
      <div class="animate__zoomIn animateClass">
        <h1>{{ $t('leading_the_industry_in_high_definition') }}</h1>
        <p>
          INDUSTRY LEADER OF HD FULL-COLOR VISION AND EDGE INTELLIGENT COMPUTING
        </p>
      </div>
    </div>
    <div class="introduce">
      <div class="contentDiv video">
        <item-title
          :title="$t('illuminating_every_darkness_in_the_world')"
          sub-title="illuminating every darkness in the world"
          dark
        />
        <div class="video-menu">
          <div
            class="item px-8 md:mx-[80px] md:text-[20px]"
            :class="activeVideoId === menu.id && 'activeVideo'"
            v-for="menu in videoList"
            :key="menu.id"
            @mousemove="handleMouseMoveVideo(menu.id)"
          >
            {{ $t(menu.title) }}
          </div>
        </div>
        <video
          v-show="activeVideoId === 1"
          :src="$i18n.locale == 'en' ? '/video/video1_en.mp4' : '/video/video1.mp4'"
          autoplay
          loop
          controls
          muted
        ></video>
        <video
          v-show="activeVideoId === 2"
          src="/video/video2.mp4"
          autoplay
          loop
          controls
          muted
        ></video>
      </div>
    </div>
    <div class="product">
      <div class="contentDiv">
        <item-title title="产品" sub-title="Product" />
        <div class="product-wrap">
          <div class="item">
            <div class="img">
              <img src="@/assets/images/product1.png" alt="" width="285px"/>
            </div>
            <div class="title">{{ $t('high_definition_full_color_night_vision_system') }}</div>
            <div class="btn" @click="handleClickToDetail(1)">{{ $t('details') }}</div>
          </div>
          <div class="item">
            <div class="img">
              <img src="@/assets/images/product3.png" alt="" width="265px"/>
            </div>
            <div class="title">{{ $t('lingying_industrial_edge_intelligent_camera') }}</div>
            <div class="btn" @click="handleClickToDetail(2)">{{ $t('details') }}</div>
          </div>
          
          <div class="item">
            <div class="img">
              <img src="@/assets/images/product2.jpg" alt="" width="235px"/>
            </div>
            <div class="title">{{ $t('lingying_industrial_edge_intelligent_camera') }}</div>
            <div class="btn" @click="handleClickToDetail(2)">{{ $t('details') }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="enterprise">
      <div class="contentDiv">
        <item-title title="企业资质" sub-title="Enterprise qualification" />
        <certificate/>
      </div>
    </div>

    <div class="contentDiv">
      <item-title title="合作伙伴" sub-title="cooperative partner" />
      <div class="partner">
        <div class="item" v-for="item in indexData.partner.list" :key="item.id">
          <img :src="item.thumb" alt="" />
          <p>{{ item.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import itemTitle from "@/components/item-title.vue"
import consulting from "@/components/consulting.vue"
import certificate from "@/components/certificate.vue"
import "animate.css/animate.css"
import { index } from "@/api/index"
export default {
  components: {
    itemTitle,
    consulting,
    certificate
  },
  data() {
    return {
      layout: {
        xs: 24,
        sm: 12,
        md: 12,
        lg: 12,
      },

      active: 3,
      entry: [],
      indexData: {
        banner: {
          list: [],
        },
        entry: {
          list: [],
        },
        partner: {
          list: [],
        },
      },
      cases: {},
      projectShow: false,
      newsShow: false,
      consultShow: false,
      list: [
        {
          name: "核心技术",
          desc: "图像增强/复原",
          img: require("@/assets/images/hxjs.png"),
          url: "/technology",
          style: require("@/assets/images/hxjs_bg.png"),
        },
        {
          name: "产品中心",
          desc: "自主研发，稳定性高，适用于多场景",
          img: require("@/assets/images/cpzx.png"),
          url: "/product",
          style: require("@/assets/images/product.png"),
        },
        {
          name: "案例展示",
          desc: "智能分析，解决行业实际应用需求",
          img: require("@/assets/images/alzs.png"),
          url: "/solution",
          style: require("@/assets/images/case.png"),
        },
        {
          name: "新闻资讯",
          desc: "记录最新资讯，了解瓴鹰向前迈进的每一个瞬间。",
          img: require("@/assets/images/mask.png"),
          style: require("@/assets/images/news_bg.png"),
          url: "/news",
        },
      ],
      videoList: [
        { id: 1, title: 'high_definition_full_color_night_vision_system' },
        { id: 2, title: 'industrial_edge_intelligent_camera' },
      ],
      activeVideoId: 1,
      timer: "",
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.windowScroll) //销毁滚动事件
    clearInterval(this.timer)
  },
  mounted() {
    window.addEventListener("scroll", this.windowScroll) //监听页面滚动
    this._index()
  },
  methods: {
    windowScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      if (scrollTop >= 780) {
        this.projectShow = true
      }
      if (scrollTop >= 3800) {
        this.newsShow = true
      }
      if (scrollTop >= 4800) {
        this.consultShow = true
      }
    },
    // 唤起QQ
    openQQ() {
      window.open(`http://wpa.qq.com/msgrd?v=3&uin=${this.indexData.foot.qq}`)
    },
    interval() {
      this.timer = setInterval(() => {
        this.entry.splice(0, 1)
        this.active++
        if (this.active >= this.indexData.entry.count) {
          this.active = 0
        }
        this.entry.push(this.indexData.entry.list[this.active])
      }, 1500)
    },
    // 首页数据
    _index() {
      index().then((res) => {
        this.indexData = res.data
        this.cases = this.indexData.cases.list[this.active]
        if (this.indexData.entry.count > 4) {
          for (let i = 0; i < 4; i++) {
            this.entry.push(res.data.entry.list[i])
          }
          this.interval()
        } else {
          this.entry = res.data.entry.list
        }
      })
    },
    handleMouseMoveVideo(id) {
      this.activeVideoId = id
    },
    handleClickToDetail(id) {
      this.$router.push(`productDetail/${id}`)
    }
  },
}
</script>

<style scoped lang="less">
/deep/.el-carousel__container {
  height: 600px;

  .el-carousel__indicators {
    bottom: 60px;

    .el-carousel__button {
      height: 4px;
    }
  }
}

.contentDiv {
  max-width: 1200px;
  margin: auto;
}

.animateClass {
  animation-delay: 0s;
  animation-duration: 2.5s;
  animation-iteration-count: 1;
}

.banner {
  height: 873px;
  width: 100%;
  object-fit: cover;
  background: url(@/assets/images/NAT2021-全黑-首页.png) no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
  text-align: center;
}

.banner h1 {
  font-size: 60px;
  margin: 0;
  padding-top: 15%;
}

.banner p {
  padding-top: 85px;
  font-size: 20px;
}

.introduce {
  text-align: center;
  background: url("@/assets/images/introduce-bg.png") no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 70px;
  background-color: #04060D;

  .name {
    font-size: 30px;
    color: #333;
    font-weight: 800;
  }

  .remark {
    font-size: 16px;
    color: #999;
    margin-top: 4px;
  }

  .gap {
    width: 43px;
    height: 4px;
    background: #3485fb;
    margin: 0 auto;
    margin-top: 12px;
    margin-bottom: 70px;
  }

  /deep/.el-row {
    margin: 0 !important;
  }

  img {
    width: 100%;
    height: 100%;
    max-height: 200px;
    -webkit-user-drag: none;
  }

  .introduce-img {
    width: 220px;
    height: 120px;
    object-fit: contain;
  }

  .introduce-text {
    font-size: 16px;
    color: #333;
    font-weight: 500;
    margin: 15px 0;
  }
}

.enterprise {
  background-color: #f5f5f5;
  padding-bottom: 50px;
}

.product {
  padding-bottom: 70px;

  .product-wrap {
    display: flex;
    .item {
      flex: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: 22px;
        color: #0f0f0f;
      }

      .img {
        text-align: center;
        height: 237px;
        display: flex;
        align-items: center;
      }

      .btn {
        cursor: pointer;
        font-size: 17px;
        margin-top: 50px;
        color: #fff;
        padding: 7px 25px;
        border-radius: 30px;
        background-color: #3485fb;
        position: relative;

        &::before {
          content: "";
          display: block;
          width: 53px;
          height: 5px;
          background-color: #3485fb;
          position: absolute;
          top: -30px;
          left:0;
          right: 0;
          margin: 0 auto;
        }
      }
    }
  }
}

.video {
  .video-menu {
    display: flex;
    justify-content: center;
    padding-bottom: 40px;

    .item {
      color: #9f9f9f;
      cursor: pointer;
      font-weight: 800;
      position: relative;
      border-bottom: 5px solid transparent;
      padding-bottom: 5px;
    }

    .activeVideo {
      color: #fff;
      transform: scale(1.2);
      border-bottom: 5px solid #3485fb;
    }
  }
}

.partner {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 70px;

  .item {
    width: 33.3%;
    height: 274px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
      box-shadow: 0 0 10px #aaa;
      border-radius: 8px;
    }

    img {
      width: 220px;
      height: 120px;
      object-fit: contain;
    }

    p {
      margin: 15px 0;
    }
  }
}

.newsItem {
  padding: 50px 0;
}

.newsItem:hover {
  box-shadow: 0 0 10px #aaa;
  border-radius: 8px;
}

video,
iframe {
  width: 100%;
}

.animate {
  animation-delay: 0s;
  animation-duration: 2s;
  animation-iteration-count: 1;
}

@media screen and (max-width: 750px) {
  .partner {
    .item {
      width: 100% !important;
    }
  }

  .product {
    .product-wrap {
      display: block;
    }
  }

  .banner {
    height: 350px;
    padding: 0 30px;

    h1 {
      font-size: 30px;
    }

    p {
      padding-top: 40px;
    }
  }
}
</style>
