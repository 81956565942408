<template>
  <div>
    <navBar/>
    <router-view></router-view>
    <consulting/>
    <tabbar/>
  </div>
</template>

<script>
import tabbar from "@/components/tabbar.vue"
import navBar from "@/components/navBar.vue"
import consulting from "@/components/consulting.vue"
export default {
components: {
  navBar,
  tabbar,
  consulting,
},
}
</script>